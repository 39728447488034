import { config } from "@/config/config";

export function getMentorshipMyStudentsUrl() {
  return `${config.apiUrl}${config.urls.baseMentorship}/${config.mentorship.myStudents.url}`;
}

export function getMentorshipMyStudentsMethod() {
  return config.mentorship.myStudents.method;
}

export function addMentorshipMyStudentUrl() {
  return `${config.apiUrl}${config.urls.baseMentorship}/${config.mentorship.addStudent.url}`;
}

export function addMentorshipMyStudentMethod() {
  return config.mentorship.addStudent.method;
}

export function getMentorshipMyStudentUrl(studentId) {
  return `${config.apiUrl}${config.urls.baseMentorship}/${config.mentorship.myStudent.url}/${studentId}`;
}

export function getMentorshipMyStudentMethod() {
  return config.mentorship.myStudent.method;
}

export function getMentorshipStudentGroupsUrl() {
  return `${config.apiUrl}${config.urls.baseMentorship}/${config.mentorship.studentGroup.url}`;
}

export function getMentorshipStudentGroupsMethod() {
  return config.mentorship.studentGroup.method;
}

export function addMentorshipStudentGroupUrl() {
  return `${config.apiUrl}${config.urls.baseMentorship}/${config.mentorship.addStudentGroup.url}`;
}

export function addMentorshipStudentGroupMethod() {
  return config.mentorship.addStudentGroup.method;
}

export function getMentorshipStudentGroupUrl(studentGroupId) {
  return `${config.apiUrl}${config.urls.baseMentorship}/${config.mentorship.studentGroup.url}/${studentGroupId}`;
}

export function getMentorshipStudentGroupMethod() {
  return config.mentorship.studentGroup.method;
}

export function getMentorshipStudentGroupMembersUrl(studentGroupId) {
  return `${config.apiUrl}${config.urls.baseMentorship}/${config.mentorship.studentGroup.url}/${studentGroupId}/members`;
}

export function getMentorshipStudentGroupMembersMethod() {
  return config.mentorship.studentGroup.method;
}

export function addMentorshipStudentGroupMemberUrl(studentGroupId) {
  return `${config.apiUrl}${config.urls.baseMentorship}/${config.mentorship.addStudentGroupMember.url}/${studentGroupId}/members`;
}

export function addMentorshipStudentGroupMemberMethod() {
  return config.mentorship.addStudentGroupMember.method;
}

export function removeMentorshipStudentGroupMemberUrl(studentGroupId, memberId) {
  return `${config.apiUrl}${config.urls.baseMentorship}/${config.mentorship.removeStudentGroupMember.url}/${studentGroupId}/members/${memberId}`;
}

export function removeMentorshipStudentGroupMemberMethod() {
  return config.mentorship.removeStudentGroupMember.method;
}

export function listMentorshipPackagesUrl() {
  return `${config.apiUrl}${config.urls.baseMentorship}/${config.mentorship.listMentorshipPackages.url}`;
}

export function listMentorshipPackagesMethod() {
  return config.mentorship.listMentorshipPackages.method;
}

export function assignMentorUrl() {
  return `${config.apiUrl}${config.urls.baseMentorship}/${config.mentorship.assignMentor.url}`;
}

export function assignMentorMethod() {
  return config.mentorship.assignMentor.method;
}