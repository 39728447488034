<template>
  <div>
    <Multiselect
      track-by="id"
      label="label"
      v-model="selectedOptions"
      v-bind:options="options"
      v-bind:multiple="multiple"
      v-bind:hideSelected="true"
      :close-on-select="false"
      @select="onSelect"
      @remove="onRemove"
      v-bind:placeholder="placeholder"
      v-bind:selectLabel="$t('components.multiselect.select')"
      v-bind:selectedLabel="$t('components.multiselect.selected')"
      v-bind:deselectLabel="$t('components.multiselect.remove')"
    >
      <template v-slot:noOptions>
        {{ $t("components.multiselect.noOptions") }}
      </template>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "RMMultiSelect",
  data() {
    return {
      selectedOptions: []
    }
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  components: {Multiselect},
  computed: {
    placeholder() {
      if(this.multiple){
        return this.$t("components.multiselect.selectMultiple");
      }
      return this.$t("components.multiselect.selectOne");
    }
  },
  methods: {
    onSelect(){
      this.$emit('onChange', this.selectedOptions);
    },
    onRemove(){
      this.$emit('onChange', this.selectedOptions);
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
