import {fetcher, handleResponse} from "@/_helpers/api";
import {
  addMentorshipMyStudentMethod,
  addMentorshipMyStudentUrl,
  addMentorshipStudentGroupMemberMethod,
  addMentorshipStudentGroupMemberUrl,
  addMentorshipStudentGroupMethod,
  addMentorshipStudentGroupUrl,
  assignMentorMethod,
  assignMentorUrl,
  getMentorshipMyStudentMethod,
  getMentorshipMyStudentsMethod,
  getMentorshipMyStudentsUrl,
  getMentorshipMyStudentUrl,
  getMentorshipStudentGroupMembersMethod,
  getMentorshipStudentGroupMembersUrl,
  getMentorshipStudentGroupMethod,
  getMentorshipStudentGroupsMethod,
  getMentorshipStudentGroupsUrl,
  getMentorshipStudentGroupUrl,
  listMentorshipPackagesMethod,
  listMentorshipPackagesUrl,
  removeMentorshipStudentGroupMemberMethod, removeMentorshipStudentGroupMemberUrl
} from "@/_helpers/mentorship_helper";

export const mentorshipService = {
  listStudentGroups,
  listMyStudents,
  addMyStudent,
  addStudentGroup,
  getMyStudent,
  getStudentGroup,
  getStudentGroupMembers,
  addStudentGroupMember,
  removeStudentGroupMember,
  listMentorshipPackages,
  assignMember,
}

function listMyStudents() {
  let requestOptions = {
    method: getMentorshipMyStudentsMethod(),
    headers: { "Content-Type": "application/json" },
  };

  return fetcher(getMentorshipMyStudentsUrl(), requestOptions).then(handleResponse);
}

function listStudentGroups() {
  let requestOptions = {
    method: getMentorshipStudentGroupsMethod(),
    headers: { "Content-Type": "application/json" },
  };

  return fetcher(getMentorshipStudentGroupsUrl(), requestOptions).then(handleResponse);
}

function addMyStudent(data) {
  let requestOptions = {
    method: addMentorshipMyStudentMethod(),
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetcher(addMentorshipMyStudentUrl(), requestOptions).then(handleResponse);
}

function addStudentGroup(data) {
  let requestOptions = {
    method: addMentorshipStudentGroupMethod(),
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetcher(addMentorshipStudentGroupUrl(), requestOptions).then(handleResponse);
}

function getMyStudent(studentId) {
  let requestOptions = {
    method: getMentorshipMyStudentMethod(),
    headers: { "Content-Type": "application/json" },
  };

  return fetcher(getMentorshipMyStudentUrl(studentId), requestOptions).then(handleResponse);
}

function getStudentGroup(studentGroupId) {
  let requestOptions = {
    method: getMentorshipStudentGroupMethod(),
    headers: { "Content-Type": "application/json" },
  };

  return fetcher(getMentorshipStudentGroupUrl(studentGroupId), requestOptions).then(handleResponse);
}

function getStudentGroupMembers(studentGroupId) {
  let requestOptions = {
    method: getMentorshipStudentGroupMembersMethod(),
    headers: { "Content-Type": "application/json" },
  };

  return fetcher(getMentorshipStudentGroupMembersUrl(studentGroupId), requestOptions).then(handleResponse);
}

function addStudentGroupMember(studentGroupId, data) {
  let requestOptions = {
    method: addMentorshipStudentGroupMemberMethod(),
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetcher(addMentorshipStudentGroupMemberUrl(studentGroupId), requestOptions).then(handleResponse);
}

function removeStudentGroupMember(studentGroupId, memberId) {
  let requestOptions = {
    method: removeMentorshipStudentGroupMemberMethod(),
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({}),
  };

  return fetcher(removeMentorshipStudentGroupMemberUrl(studentGroupId, memberId), requestOptions).then(handleResponse);
}

function listMentorshipPackages() {
  let requestOptions = {
    method: listMentorshipPackagesMethod(),
    headers: { "Content-Type": "application/json" },
  };

  return fetcher(listMentorshipPackagesUrl(), requestOptions).then(handleResponse);
}

function assignMember() {
  let requestOptions = {
    method: assignMentorMethod(),
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({}),
  };

  return fetcher(assignMentorUrl(), requestOptions).then(handleResponse);
}